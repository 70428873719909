import { systemNames } from 'wikr-core-analytics';

import config from 'config';

export const STRICTLY_NECESSARY_COOKIE = 'C0001';
export const PERFORMANCE_COOKIE = 'C0002';
export const FUNCTIONAL_COOKIE = 'C0003';
export const TARGETING_COOKIE = 'C0004';
export const SOCIAL_MEDIA_COOKIE = 'C0005';

export const TERMS_OF_USE_EVENT_VALUE = 'term_of_use';
export const REFUND_POLICY_EVENT_VALUE = 'refund_policy';
export const PRIVACY_POLICY_EVENT_VALUE = 'privacy_policy';

export const COOKIE_GROUP_LIST = [STRICTLY_NECESSARY_COOKIE, PERFORMANCE_COOKIE, TARGETING_COOKIE];

export const COOKIE_ANALYTIC_LIST = {
    [STRICTLY_NECESSARY_COOKIE]: [],
    [PERFORMANCE_COOKIE]: [],
    [FUNCTIONAL_COOKIE]: [],
    [TARGETING_COOKIE]: [
        {
            name: systemNames.ga,
            id: config.GOOGLE_ID,
            config: {
                googleAdsId: config.GOOGLE_ADS_ID,
                googleAdsShopId: config.GOOGLE_ADS_SHOP_ID,
            },
        },
        {
            name: systemNames.pixel,
            id: config.PIXEL_ID,
            config: {
                reservedWord: {
                    viewcontent: 'ViewContent',
                    lead: 'Lead',
                    registration: 'Registration',
                    sign_up: 'SIGN_UP',
                },
            },
        },
        {
            name: systemNames.ga4,
            id: config.GOOGLE_4_ID,
        },
        {
            name: systemNames.tiktok,
            id: config.TIKTOK_ID,
        },
    ],
    [SOCIAL_MEDIA_COOKIE]: [],
};
