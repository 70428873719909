import { Main } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';

import { COOKIE_GROUP_LIST } from 'constants/analytics';

import sentry from 'services/Sentry/SentryInstance';

import { getAnalyticInitList, getAllAnalyticInitList } from './helper';

const stringToArray = (str) => (str ? str.slice(1, -1).split(',') : []);

const isAcceptedAllCookies = (cookieList) => COOKIE_GROUP_LIST.every((groupId) => cookieList.includes(groupId));

export default class OneTrust {
    #instance;

    constructor(OneTrust) {
        this.#instance = OneTrust;
        this.isAllowedAll =
            OneTrust?.IsAlertBoxClosed() && isAcceptedAllCookies(stringToArray(window?.OptanonActiveGroups));
        this.isBannerClosed = OneTrust?.IsAlertBoxClosed();
        this.acceptedCookieList = OneTrust?.IsAlertBoxClosed() ? stringToArray(window?.OptanonActiveGroups) : [];
    }

    setError(methodName) {
        sentry.logError(new Error(`OneTrust method ${methodName} don't work`), SENTRY_APP, ERROR_LEVELS.CRITICAL);
    }

    consentHandler(oneTrustEvent) {
        const { detail } = oneTrustEvent;

        if (!this.isBannerClosed) {
            this.acceptedCookieList = detail;
        }

        const cookieList = this.isBannerClosed ? this.acceptedCookieList : detail;

        this.isAllowedAll = isAcceptedAllCookies(cookieList);

        const activeSystems = getAnalyticInitList(cookieList);

        if (this.isAllowedAll && !this.isBannerClosed) {
            if (activeSystems.length) {
                Main.initSystems({ activeSystems });
                Main.trackNew({ eventData: 'full_cookie_consent' });

                this.isBannerClosed = true;
            }
        } else if (activeSystems.length && this.isBannerClosed) {
            Main.initSystems({ activeSystems });
        }
    }

    init() {
        if (this.isBannerClosed) {
            this.consentHandler({ detail: this.acceptedCookieList });
        } else {
            this.#instance?.OnConsentChanged
                ? this.#instance.OnConsentChanged(this.consentHandler)
                : this.setError('OnConsentChanged');
        }
    }

    rejectAll() {
        this.#instance?.RejectAll ? this.#instance?.RejectAll() : this.setError('rejectAll');
    }

    allowAll() {
        if (!this.isAllowedAll) {
            const activeSystems = getAllAnalyticInitList();

            Main.initSystems({ activeSystems });

            this.isAllowedAll = true;
            this.#instance?.AllowAll ? this.#instance.AllowAll() : this.setError('AllowAll');
        }
    }
}
