export const onboardingRoutes = [
    {
        path: 'OnboardingPages/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'OnboardingPages/ObSocialProofFull',
        route: 'ob-social-proof-full',
    },
    {
        path: 'OnboardingPages/ObGoalsOver',
        route: 'ob-goals',
    },
    {
        path: 'OnboardingPages/CurrentBody',
        route: 'ob-body-type',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'ob-body-type-target',
    },
    {
        path: 'OnboardingPages/TargetZone',
        route: 'ob-target-zones',
    },
    {
        path: 'OnboardingPages/ObTargetZonesA',
        route: 'ob-target-zones-a',
    },
    {
        path: 'OnboardingPages/ObTargetZonesFull',
        route: 'ob-target-zones-full',
    },
    {
        path: 'OnboardingPages/BodyImage',
        route: 'ob-body-image',
    },
    {
        path: 'OnboardingPages/ActivityLevel',
        route: 'ob-activity-level',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-walking',
    },
    {
        path: 'OnboardingPages/PushupsSquats',
        route: 'ob-pushups-squats',
    },
    {
        path: 'OnboardingPages/YogaLevel',
        route: 'ob-yoga-level',
    },
    {
        path: 'OnboardingPages/BendingKnees',
        route: 'ob-bending-knees',
    },
    {
        path: 'OnboardingPages/MealsFeel',
        route: 'ob-meals-feel',
    },
    {
        path: 'OnboardingPages/ObSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/ObWater',
        route: 'ob-water',
    },
    {
        path: 'OnboardingPages/ObDiets',
        route: 'ob-diets',
    },
    {
        path: 'OnboardingPages/ObHeight',
        route: 'ob-height',
    },
    {
        path: 'OnboardingPages/ObCurrentWeight',
        route: 'ob-weight',
    },
    {
        path: 'OnboardingPages/ObTargetWeight',
        route: 'ob-weight-target',
    },
    {
        path: 'OnboardingPages/ObAge',
        route: 'ob-age',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'feedback-summary-fitlevel',
    },
    {
        path: 'OnboardingPages/CreatingA',
        route: 'creating',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'OnboardingPages/EmailConsentFull',
        route: 'email-consent-full',
    },
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObTagsB',
        route: 'ob-tags-b',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObPregnant',
        route: 'ob-pregnant',
    },
    {
        path: 'OnboardingPages/ObYogaEffect',
        route: 'ob-yoga-effect',
    },
    {
        path: 'OnboardingPages/ObFlexibility',
        route: 'ob-flexibility',
    },
    {
        path: 'OnboardingPages/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'OnboardingPages/ObUnfortunately',
        route: 'ob-unfortunately',
    },
    {
        path: 'OnboardingPages/ObFortunately',
        route: 'ob-fortunately',
    },
    {
        path: 'OnboardingPages/ObFlexibility',
        route: 'ob-flexibility',
    },
    {
        path: 'OnboardingPages/PilatesLevel',
        route: 'ob-pilates-level',
    },
    {
        path: 'OnboardingPages/EmailAccount',
        route: 'email-account',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/EmailB',
        route: 'email-b',
    },
    {
        path: 'OnboardingPages/EmailC',
        route: 'email-c',
    },
    {
        path: 'OnboardingPages/EmailAFull',
        route: 'email-a-full',
    },
    {
        path: 'OnboardingPages/EmailAccountA',
        route: 'email-account-a',
    },
    {
        path: 'OnboardingPages/ObSocialProofAges',
        route: 'ob-social-proof-ages',
    },
    {
        path: 'OnboardingPages/ObSocialProofMix',
        route: 'ob-social-proof-mix',
    },
    {
        path: 'OnboardingPages/ObGoalsOverMix',
        route: 'ob-goals-mix',
    },
    {
        path: 'OnboardingPages/CurrentBodyMix',
        route: 'ob-body-type-mix',
    },
    {
        path: 'OnboardingPages/TargetBodyMix',
        route: 'ob-body-type-target-mix',
    },
    {
        path: 'OnboardingPages/TargetZonesMix',
        route: 'ob-target-zones-mix',
    },
    {
        path: 'OnboardingPages/ObFamiliarityFull',
        route: 'ob-familiarity-full',
    },
    {
        path: 'OnboardingPages/ObTargetZonesB',
        route: 'ob-target-zones-b',
    },
    {
        path: 'OnboardingPages/ObTargetZonesC',
        route: 'ob-target-zones-c',
    },
    {
        path: 'OnboardingPages/ObGoalsA',
        route: 'ob-goals-a',
    },
    {
        path: 'OnboardingPages/ObGoalsB',
        route: 'ob-goals-b',
    },
    {
        path: 'OnboardingPages/ObGoalsFeedbackA',
        route: 'ob-goals-feedback-a',
    },
    {
        path: 'OnboardingPages/ObGoalsFeedbackB',
        route: 'ob-goals-feedback-b',
    },
    {
        path: 'OnboardingPages/CurrentBodyA',
        route: 'ob-current-body-type-a',
    },
    {
        path: 'OnboardingPages/TargetBodyA',
        route: 'ob-target-body-type-a',
    },
    {
        path: 'OnboardingPages/ObYogaLevelPlanSocproof',
        route: 'ob-yoga-level-plan-socproof',
    },
    {
        path: 'OnboardingPages/ObYogaLevelDetailsCoach',
        route: 'ob-yoga-level-details-coach',
    },
    {
        path: 'OnboardingPages/ObSex',
        route: 'ob-sex',
    },
    {
        path: 'OnboardingPages/ObGoalsMeditation',
        route: 'ob-goals-meditation',
    },
    {
        path: 'OnboardingPages/ObCurrentEmotions',
        route: 'ob-current-emotions',
    },
    {
        path: 'OnboardingPages/ObPositive',
        route: 'ob-positive',
    },
    {
        path: 'OnboardingPages/ObUnfortunatelyStress',
        route: 'ob-unfortunately-stress',
    },
    {
        path: 'OnboardingPages/ObStressLook',
        route: 'ob-stress-look',
    },
    {
        path: 'OnboardingPages/ObStressTime',
        route: 'ob-stress-time',
    },
    {
        path: 'OnboardingPages/ObSleepProblems',
        route: 'ob-sleep-problems',
    },
    {
        path: 'OnboardingPages/ObFocus',
        route: 'ob-focus',
    },
    {
        path: 'OnboardingPages/ObSelftime',
        route: 'ob-selftime',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general1',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general2',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general3',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general4',
    },
    {
        path: 'OnboardingPages/ObGeneral',
        route: 'ob-general5',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryMeditation',
        route: 'feedback-summary-meditation',
    },
    {
        path: 'OnboardingPages/EmailAFull2',
        route: 'email-a-full2',
    },
    {
        path: 'OnboardingPages/EmailConsentFull2',
        route: 'email-consent-full2',
    },
    {
        path: 'OnboardingPages/ObSleepFull',
        route: 'ob-sleep-full',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryFitLevelFull',
        route: 'feedback-summary-fitlevel-full',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-a',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-b',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-c',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-d',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-e',
    },
    {
        path: 'OnboardingPages/ObFeedback',
        route: 'ob-feedback-f',
    },
    {
        path: 'OnboardingPages/ObUserStory',
        route: 'ob-user-story',
    },
    {
        path: 'OnboardingPages/ObUserStoryFm',
        route: 'ob-user-story-fm',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingAConfig',
        route: 'creating-a',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingBConfig',
        route: 'creating-b',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingCConfig',
        route: 'creating-c',
    },
    {
        path: 'OnboardingPages/CreatingConfig/CreatingDConfig',
        route: 'creating-d',
    },
];
