// TODO: fix in https://app.asana.com/0/1201994806276539/1205107468358935/f
// eslint-disable-next-line import/named
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classNames from 'classnames/bind';

import { selectHideProgressBar } from 'redux/uiEffects/selectors';
import { selectOnboardingScreensList } from 'redux/onboadring/selectors';

import { GET_MY_PLAN_BTN } from 'constants/payments';

import { formattedUrlHelper } from 'helpers/utils';

import Logo from 'components/Logo';
import type { HeaderTypes } from 'components/Header/types';
import Button from 'components/Button';

import styles from './Header.module.css';

import { ReactComponent as SkipArrow } from 'assets/img/items/skipArrow.svg';
import { ReactComponent as BackIcon } from './img/icon-back.svg';

const cx = classNames.bind(styles);

function Header({
    title = GET_MY_PLAN_BTN,
    squareBtn = false,
    boldButton,
    rowElements,
    history,
    centered,
    getPlanClick,
    disabled,
    onSkipClick,
}: HeaderTypes) {
    const headerClass = cx('header', { rowElements });

    const onboardingScreensList = useSelector(selectOnboardingScreensList);
    const isHideProgressBar = useSelector(selectHideProgressBar);

    const currentLocationName = formattedUrlHelper(location.pathname);
    const currentPageCount = onboardingScreensList[currentLocationName]?.index;
    const screensNumber = Object.keys(onboardingScreensList).length;

    const calculateProgressWidth = () => (currentPageCount / screensNumber) * 100 + '%';

    const isOnboardingHeader = onboardingScreensList[currentLocationName] && !isHideProgressBar;

    return (
        <header className={headerClass}>
            <div className={styles.headerContainer}>
                <div className={styles.top}>
                    {isOnboardingHeader && (
                        <button type="button" className={styles.backButton} onClick={() => history.goBack()}>
                            <BackIcon className={styles.iconBack} />
                        </button>
                    )}
                    <Logo
                        isOnboarding={Boolean(onboardingScreensList[currentLocationName])}
                        centered={centered}
                        onboardingLogo={isOnboardingHeader}
                    />
                    {isOnboardingHeader && (
                        <div className={styles.pageCounter}>
                            <span className={styles.pageStart}>{currentPageCount}</span>
                            <span className={styles.pageSeparator}>/</span>
                            <span className={styles.pageEnd}>{screensNumber}</span>
                        </div>
                    )}
                </div>
                {isOnboardingHeader && (
                    <div className={styles.progressBox}>
                        <div className={styles.progressBar} style={{ width: calculateProgressWidth() }} />
                    </div>
                )}
                {getPlanClick && (
                    <Button
                        dataLocator="headerNavBtn"
                        onClick={getPlanClick}
                        title={title}
                        primary
                        rounded
                        small
                        squareBtn={squareBtn}
                        disabled={disabled}
                        bold={boldButton}
                    />
                )}
                {onSkipClick && (
                    <button onClick={onSkipClick} className={styles.skipButton}>
                        <Trans>skip</Trans>
                        <SkipArrow />
                    </button>
                )}
            </div>
        </header>
    );
}

export default withRouter(Header);
