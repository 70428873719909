import { selectTestaniaName } from 'redux/testania/selectors';
import { setCompleteOBs, setTestaniaLoginStatus } from 'redux/testania/actions';
import { all, call, delay, put, select, takeLatest, take } from 'redux-saga/effects';

import { SET_USER_TOKEN, SIGN_UP_SUCCESS } from 'redux/user/actionTypes';
import { signUp } from 'redux/user/actions';
import { SKIP_ONBOARDING } from 'redux/onboadring/actionTypes';
import { setOccasionResultConfig, skipOnboarding } from 'redux/onboadring/actions';

import { DEFAULT_USER_PAYLOAD } from 'constants/user';
import { OCCASION_RESULT_CONFIG } from 'constants/skipOnboarding';

import { generateRandomIntBetween } from 'helpers/utils';

function* skip({ payload }: ReturnType<typeof skipOnboarding>) {
    const email = `ygw3+skipob${generateRandomIntBetween(100_000, 999_999)}@amazingapps.tech`;

    try {
        const completeObs: string = yield select(selectTestaniaName);

        yield put(setCompleteOBs(completeObs));
        yield put(setTestaniaLoginStatus(true));

        yield put(
            signUp({
                payload: { ...DEFAULT_USER_PAYLOAD, email },
                toNextPage: () => ({}),
                setError: () => ({}),
            })
        );
        yield put(setOccasionResultConfig(OCCASION_RESULT_CONFIG));

        yield all([take(SET_USER_TOKEN), take(SIGN_UP_SUCCESS)]);

        yield delay(0);

        yield call(payload.reload);
    } catch (error) {
        console.warn(`Skip onboarding warn ${error}`);
    }
}

export const skipOnBoarding = [takeLatest(SKIP_ONBOARDING, skip)];
