import { Provider } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'sentry-utils';

import { setPlatform } from 'redux/user/actions';
import { setUrlParams as setUrlParamsToStore } from 'redux/onboadring/actions';

import sentry from 'services/Sentry/SentryInstance';

import { getDeviceOS, isEmpty, getUrlParams as getUrlParamsUtil } from 'helpers/utils';

const getResolveParams = async (value) => {
    return new Promise((resolve) => {
        resolve(value);
    });
};

export const setAnalyticData = async ({ country, abTestName }) => {
    await Provider.provideData({
        country: async () => await getResolveParams(country),
        abTestName: async () => await getResolveParams(abTestName),
        clientHandlerFn: (error) => sentry.logError(error, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR, {}),
    });
};

export const setUrlParams = (dispatch) => {
    const params = getUrlParamsUtil();

    if (!isEmpty(params)) dispatch(setUrlParamsToStore(params));
};

export const setUserPlatform = (dispatch) => {
    const platform = getDeviceOS();

    dispatch(setPlatform(platform));
};
