import { ActionEventAction, ElementEventAction, EventTrigger, Main, ITrackNew } from 'wikr-core-analytics';

import { changePathNameToScreenId } from '../helpers';

import { TrackClickData, PurchaseData, PurchaseOption } from '../types';

export const trackScreenLoad = (screenIdParam?: string) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const trackScreenLoadPopup = (screenIdParam?: string) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.popup,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const trackEventFixed = ({ eventData, data }: ITrackNew) => {
    Main.trackNew({
        eventData,
        ...(data && { data }),
    });
};

export const trackClick = ({ elementEvent, actionEvent, eventLabel, screenIdParam, data = {} }: TrackClickData) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId },
        ...(elementEvent && actionEvent && { actionData: { elementEvent, actionEvent } }),
        ...(eventLabel && { data: { event_label: eventLabel, ...data } }),
    });
};

export const getDefaultAmazonAnalyticPurchaseData = () => Main.getDefaultAmazonAnalyticPurchaseData();

export const purchase = (data: PurchaseData, purchaseOptions?: PurchaseOption) =>
    Main.purchaseNew(data, purchaseOptions);

export const pendingPurchase = (data: PurchaseData) => Main.customData('purch_pending', data);
