export const onboardingScreensList = [
    'ob-goals',
    'ob-body-type',
    'ob-body-type-target',
    'ob-target-zones',
    'ob-body-image',
    'ob-activity-level',
    'ob-walking',
    'ob-pushups-squats',
    'ob-yoga-level',
    'ob-bending-knees',
    'ob-meals-feel',
    'ob-sleep',
    'ob-water',
    'ob-diets',
    'ob-height',
    'ob-weight',
    'ob-weight-target',
    'ob-age',
    'ob-tags-a',
    'ob-tags-b',
    'ob-occasion',
    'ob-occasion-result',
    'ob-medications',
    'ob-pregnant',
    'ob-yoga-effect',
    'ob-target-zones-a',
    'ob-flexibility',
    'ob-unfortunately',
    'ob-restrictive-diet',
    'ob-goals-mix',
    'ob-body-type-mix',
    'ob-body-type-target-mix',
    'ob-target-zones-mix',
    'ob-pilates-level',
    'ob-familiarity-full',
    'ob-target-zones-full',
    'ob-current-body-type-a',
    'ob-target-body-type-a',
    'ob-target-zones-b',
    'ob-target-zones-c',
    'ob-yoga-level-plan-socproof',
    'ob-yoga-level-details-coach',
    'ob-goals-a',
    'ob-goals-b',
    'ob-goals-feedback-a',
    'ob-goals-feedback-b',
    'ob-sex',
    'ob-goals-meditation',
    'ob-current-emotions',
    'ob-positive',
    'ob-unfortunately-stress',
    'ob-stress-look',
    'ob-stress-time',
    'ob-sleep-problems',
    'ob-focus',
    'ob-selftime',
    'ob-general',
    'ob-general1',
    'ob-general2',
    'ob-general3',
    'ob-general4',
    'ob-general5',
    'ob-sleep-full',
];
