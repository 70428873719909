import { Main } from 'wikr-core-analytics';
import { hotjar } from 'react-hotjar';

import config from 'config';

import { HOTJAR_VERSION } from 'constants/versions';

import { getParamFromUrl, getPixelAnalyticID } from 'helpers/utils';

const HOTJAR_NUMBER_ID = +config?.HOTJAR_ID;

export const initAmazonAnalytic = () => {
    Main.initSystems({
        activeSystems: [
            {
                name: 'amazon',
                id: config.AMAZON_ID,
                config: {
                    releaseDate: config.RELEASE_DATE,
                    streamName: config.AWS_DELIVERY_STREAM_NAME,
                    envMode: config.ENV,
                },
            },
        ],
    });
};

export const initAnalytics = () => {
    const pixelID = getPixelAnalyticID();

    Main.initSystems({
        activeSystems: [
            {
                name: 'ga',
                id: config.GOOGLE_ID,
                config: {
                    googleAdsId: config.GOOGLE_ADS_ID,
                    googleAdsShopId: config.GOOGLE_ADS_SHOP_ID,
                },
            },
            {
                name: 'ga4',
                id: config.GOOGLE_4_ID,
            },
            {
                name: 'pixel',
                id: pixelID,
            },
            {
                name: 'tiktok',
                id: config.TIKTOK_ID,
            },
        ],
    });
};

export const initHotjar = () => {
    const track = getParamFromUrl('track');

    track && HOTJAR_NUMBER_ID && hotjar.initialize(HOTJAR_NUMBER_ID, HOTJAR_VERSION);
};
