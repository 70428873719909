export const startRoutes = [
    {
        path: 'StartPages/StartAge',
        route: 'start-age',
    },
    {
        path: 'StartPages/StartGender',
        route: 'start-gender',
    },
    {
        path: 'StartPages/StartAgeA',
        route: 'start-age-a',
    },
    {
        path: 'StartPages/IndexPage',
        route: 'index',
    },
    {
        path: 'StartPages/StartAgeFull2',
        route: 'start-age-full2',
    },
    {
        path: 'StartPages/StartPageGeneral',
        route: 'start-page-general',
    },
    {
        path: 'StartPages/StartAgeFull3',
        route: 'start-age-full3',
    },
];
