import { call, select } from 'redux-saga/effects';

import config from 'config';

import { Currency } from 'redux/sagas/user/interfaces';
import { getCurrency, getPaymentMethod, getUserId } from 'redux/sagas/payment/oneClickPayment';

import { PAYMENT_TYPES_NAME } from 'constants/payments';

import { getDefaultAmazonAnalyticPurchaseData } from 'services/Analytics';

import { cleanObject, generateQueryParams, getPriceFromCents } from 'helpers/utils';
import { calculateLTV } from 'helpers/payment/utils';
import { LS_KEYS } from 'helpers/localStorage';

import { CurrentProduct, PaymentMethod } from 'types/payments/payments';

export function* collectPaymentAnalyticsSaga({
    currentProduct,
    oneClickPrice,
    screenId,
    eventName,
    data,
}: {
    currentProduct: CurrentProduct;
    oneClickPrice: CurrentProduct['price'];
    screenId?: string;
    eventName?: string;
    data?: { brand: string };
}) {
    const currency: Currency = yield select(getCurrency);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const userId: string = yield select(getUserId);
    const { payment_type, ltv, period, trial, id, name } = currentProduct;

    const price: number = getPriceFromCents(oneClickPrice);
    const paymentMethodName = PAYMENT_TYPES_NAME[paymentMethod] || null;
    const abTestName: string | null = localStorage.getItem(LS_KEYS.testania_name);
    const urlParams = generateQueryParams();
    const ltvValue = calculateLTV(ltv, period);

    const content_id = trial ? name : `Product - Price ${price}`;

    const analyticData = {
        currency: currency.name,
        value: ltvValue,
        content_id,
        price,
        payment: paymentMethodName,
        card_type: data?.brand,
        eventName: eventName,
        screen_id: screenId,
        event_type: eventName || 'purchase',
        ab_test_name: abTestName,
        user_id: userId,
        order_type: payment_type,
        urlParams,
        tariff: id,
        mode: config.ENV,
        release_date: config.RELEASE_DATE,
    };

    const otherAnalyticData: object = yield call(getDefaultAmazonAnalyticPurchaseData);

    return cleanObject({ ...otherAnalyticData, ...analyticData });
}
