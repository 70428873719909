import { select, takeLatest } from 'redux-saga/effects';

import api from 'apiSingleton';

import { selectUserId } from 'redux/user/selectors';
import { RootState } from 'redux/types';
import { selectPaymentMethod } from 'redux/payment/selectors';
import * as actionTypes from 'redux/payment/actionTypes';

import { PAYMENT_TYPES_NAME } from 'constants/payments';

import { purchase } from 'services/Analytics';

import { cleanObject, deleteSlash, fromPennyToInt, generateQueryParams, getPriceFromCents } from 'helpers/utils';

import { PaymentMethod } from 'types/payments/payments';
import {
    BrainTreeTokenizePropsType,
    PurchaseEventType,
    SubscribeRequestResponseType,
} from 'types/payments/brainTreeInit';
import { ActionType, IBraintreeInit } from 'types/commonInterfaces';

import paypal from 'braintree-web/paypal';
import client from 'braintree-web/client';
import { testaniaName } from './validatePayment';

export function braintreeTokenize({
    paypalInstance,
    setIsLoading,
    toNextPage,
    onSuccess,
    currentProduct,
    analyticData,
}: BrainTreeTokenizePropsType) {
    setIsLoading(true);

    paypalInstance?.tokenize({ flow: 'vault' }, (tokenizeErr, payload: any) => {
        if (tokenizeErr) {
            if (tokenizeErr.type !== 'CUSTOMER') {
                console.error('Error tokenizing:', tokenizeErr);
            }
        } else {
            try {
                const res = api.payment.brainTreeSubscription({
                    subscription_id: currentProduct.id,
                    payment_method_nonce: payload?.nonce,
                });

                res.then(({ order: { currency, amount, subscription_id, order_id } }: SubscribeRequestResponseType) => {
                    const price = fromPennyToInt(amount);
                    const subscriptionPrice = fromPennyToInt(currentProduct?.start_price);

                    const content_id = currentProduct?.trial
                        ? `Product - Introductory Price ${price} - Subscription price ${subscriptionPrice}`
                        : `Product - Price ${price}`;

                    const options = {
                        currency,
                        price,
                        order_id,
                        subscription_id,
                        content_id,
                        card_type: null,
                        ...analyticData,
                    };

                    onSuccess?.();
                    purchase(cleanObject(options));
                    toNextPage({ isValid: true });
                    setIsLoading(false);
                });
            } catch (error) {
                toNextPage({ isValid: false });
                purchase(cleanObject(error));
                console.error('Error braintree tokenizing:', error);
            }
        }
    });
}

export function* init({ payload }: ActionType<IBraintreeInit>) {
    payload.setIsLoading(true);

    const getUserId = (state: RootState) => selectUserId(state);
    const userId: number = yield select(getUserId);
    const ab_test_name: string = yield select(testaniaName);
    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);
    const introductorySubscriptionPrice = payload.currentProduct?.start_price;

    const analyticData: PurchaseEventType = {
        product: payload.currentProduct,
        ab_test_name,
        urlParams: generateQueryParams(),
        isTrial: Boolean(payload.currentProduct?.trial),
        user_id: userId,
        tariff: payload.currentProduct.id,
        screen_id: deleteSlash(deleteSlash(payload.screenId)),
        payment: PAYMENT_TYPES_NAME[paymentMethod] || null,
        subscription_price: introductorySubscriptionPrice && fromPennyToInt(introductorySubscriptionPrice),
        value: Number(getPriceFromCents(payload.currentProduct?.ltv)),
        order_type: payload.currentProduct.payment_type,
    };

    try {
        const { token }: { token: string } = yield api.payment.getBraintreeToken();

        yield client.create({ authorization: token }, (clientErr, client) => {
            if (!clientErr) {
                payload.setIsLoading(false);

                try {
                    paypal.create({ client }, (BraintreeError, paypalInstance) => {
                        // todo: refactor to SAGA eventListener to avoid analytic's crutch
                        const paypalButton = document.getElementById('paypal-button');

                        if (paypalButton !== null) paypalButton.replaceWith(paypalButton.cloneNode(true));

                        return document.getElementById('paypal-button')?.addEventListener('click', () => {
                            braintreeTokenize({
                                paypalInstance,
                                analyticData,
                                ...payload,
                            });
                        });
                    });
                } catch (error) {
                    console.error('Error braintree init', error);
                }
            }
        });
    } catch (error) {
        console.error('Error creating braintree client:', error);
    }
}

export const initBrainTree = [takeLatest(actionTypes.INIT_BRAIN_TREE, init)];
