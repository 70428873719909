import styles from './LoaderSuspense.module.css';

import logoDefault from 'assets/img/favicon.png';

const LoaderSuspense = () => (
    <div className={styles.loaderWrapper}>
        <img src={logoDefault} className={styles.logo} alt="logo" />
    </div>
);

export default LoaderSuspense;
