import { setCompleteOBs, setCurrentBranchName, setTestaniaLoginStatus, clearCompleteOBs } from 'redux/testania/actions';

import api from 'apiSingleton';

import { setIsPaid, setUpdatedAtTime, setUserId } from 'redux/user/actions';
import { setCurrentWeight, setMeasure, setTall, setTargetWeight } from 'redux/onboadring/actions';

import { HEIGHT_CONSTRAINTS, WEIGHT_CONSTRAINTS } from 'constants/validationConstraints';
import { onboardingScreensList } from 'constants/onboardingScreensList';
import { IMPERIAL, METRIC, TALL_UNIT, WEIGHT_UNIT } from 'constants/measureUnits';
import { instructionsPagesUrlList } from 'constants/instructionsPagesUrlList';
import { COUNTRIES_IMPERIAL_SYSTEM } from 'constants/countriesList';

import { deleteSlash, isEmpty, replaceUnderscore } from 'helpers/utils';
import { LS_KEYS } from 'helpers/localStorage';

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

export const checkFbBots = () => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};

export const isInstructionPage = () => {
    const path = deleteSlash(window.location.pathname);

    return instructionsPagesUrlList.includes(path);
};

export const getBranchNameFromLocalStorage = () => {
    return localStorage.getItem(LS_KEYS.config) && JSON.parse(localStorage.getItem(LS_KEYS.config))?.branch_name;
};

export const getConfigBy = (name, defaultValue) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(name) || getBranchNameFromLocalStorage() || defaultValue;
};

export const getVariantBranchName = () => {
    const params = new URLSearchParams(window.location.search);

    return params.get('variant-name');
};

export const getCheckFlow = (payment_flow) => (flowId) => payment_flow.find((item) => item.id === flowId);

export const getValidatedStatus = (rules) => {
    for (const rule of rules) {
        if (!rule.exec(rule.args)) {
            console.error('%c Flow validation error - ' + rule.message, 'color: red;');

            return false;
        }
    }

    return true;
};

export const getScreenList = (onboardingFlow) => {
    let count = 1;

    return onboardingFlow.reduce((screenList, { id }) => {
        const onboardingFlowPage = replaceUnderscore(id);

        if (onboardingScreensList.includes(onboardingFlowPage)) {
            return { ...screenList, [onboardingFlowPage]: { index: count++ } };
        }

        return screenList;
    }, {});
};

export const getIsLogin = async (testaniaLoginStatus, token) => {
    return testaniaLoginStatus === 'true' || Boolean(token) || false;
};

const getUserSetData = async (dispatch) => {
    let user = {};

    try {
        user = await api.user.getUser();

        if (!isEmpty(user)) {
            dispatch(setUserId(user.user_id));
            dispatch(setIsPaid(user.is_paid));
            dispatch(setUpdatedAtTime(new Date()));
        }
    } catch (e) {
        console.error('getUser error', e);
    }

    return { isPaid: Boolean(user?.is_paid), isPasswordSet: Boolean(user?.is_password) };
};

export const getUserStatus = async (dispatch, token) => {
    const userStatus = token ? await getUserSetData(dispatch) : { isPaid: false, isPasswordSet: false };

    dispatch(setIsPaid(userStatus?.isPaid));

    return userStatus;
};

export const setCurrentBranchToStore = async (branch, dispatch) => {
    dispatch(setCurrentBranchName(branch));
};

export const setCompleteOBsToStore = async (branch, dispatch) => {
    dispatch(setCompleteOBs(branch));
};

export const clearCompleteOBsToStore = async (dispatch) => {
    dispatch(clearCompleteOBs());
};

export const setTestaniaLoginStatusToStore = async (status, dispatch) => {
    dispatch(setTestaniaLoginStatus(status));
};

export const setInitMeasureValues = async ({ country, dispatch, targetWeight, currentWeight }) => {
    const { poundMinValue, kilogramMinValue } = WEIGHT_CONSTRAINTS;
    const { singleFeetMinValue, centimetersMinValue } = HEIGHT_CONSTRAINTS;

    const measure = COUNTRIES_IMPERIAL_SYSTEM.includes(country) ? IMPERIAL : METRIC;

    const weight = measure === IMPERIAL ? poundMinValue : kilogramMinValue;
    const tall = measure === IMPERIAL ? singleFeetMinValue : centimetersMinValue;

    dispatch(setMeasure(measure));
    dispatch(setTall({ value: tall, unit: TALL_UNIT[measure] }));

    // Setting current/target weight is needed only for meditation flow
    // Need to remove the code below after meditation flow is removed
    !currentWeight && dispatch(setCurrentWeight({ value: weight, unit: WEIGHT_UNIT[measure] }));
    !targetWeight && dispatch(setTargetWeight({ value: weight, unit: WEIGHT_UNIT[measure] }));
};
