import LazyLoad, { forceVisible } from 'react-lazyload';
import { useEffect } from 'react';

import Placeholder from './subComponents/Placeholder';

import styles from './LazyImage.module.css';

import { ILazyImage } from './types';

function LazyImage({ height = 20, src, alt, isForceVisible = false, imgHeight }: ILazyImage) {
    const loaderHeight = { minHeight: height };
    const imageHeight = { height: imgHeight };

    useEffect(() => {
        if (isForceVisible) forceVisible();
    }, [isForceVisible]);

    return (
        <div style={loaderHeight}>
            <LazyLoad height={height} style={loaderHeight} placeholder={<Placeholder />} debounce={10}>
                <img src={src} alt={alt} className={styles.image} style={imageHeight} />
            </LazyLoad>
        </div>
    );
}

export default LazyImage;
