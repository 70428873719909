import { combineReducers, AnyAction } from 'redux';
import TestaniaReducer from './testania/reducer';

import { PartialPreloadedState } from './types';

import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './user/reducer';
import UiEffects from './uiEffects/reducer';
import PaymentReducer from './payment/reducer';
import OnboardingReducer from './onboadring/reducer';

const appReducer = combineReducers({
    user: UserReducer,
    onboarding: OnboardingReducer,
    uiEffects: UiEffects,
    payment: PaymentReducer,
    testania: TestaniaReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    // when a reset_state action is dispatched it will reset redux state
    if (action.type === 'RESET_STATE') {
        localStorage.removeItem('isResetStore');

        state = undefined;
    }

    return appReducer(state, action);
};

export const setupTestingStore = (preloadedState?: PartialPreloadedState) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
};

export default rootReducer;
